import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <header className="grid justify-items-center bg-gray-100 p-4 gap-y-1">
    <h1>
      <Link to="/">
        <img src={logo} alt={siteTitle} className="max-w-xs" />
      </Link>
    </h1>
    <nav className="flex gap-x-4">
      <Link to="/" className="text-xl">Home</Link>
      <Link to="/mini-projects" className="text-xl">Mini Projects</Link>
      <Link to="/about" className="text-xl">About</Link>
      {/* <Link to="/reviews" className="text-xl">Reviews</Link> */}
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
