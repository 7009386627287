/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import SiteHeader from "./SiteHeader"
import "./normalize.scss"
import "./moonReacher.scss"

const Layout = ({ className = '', children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <SiteHeader siteTitle={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      <main className={className}>{children}</main>
      <footer className="text-center text-xs p-4">
        © {new Date().getFullYear()} Moon Reacher.
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
