import React from 'react';

export default function ({preTitle, title, subTitle}) {
  return (
    <header className="text-center">
      {preTitle && <h2>{preTitle}</h2>}
      <h1>{title}</h1>
    </header>
  );
}
